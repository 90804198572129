import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import HouseNumber from "./houseNumber"

const OffertImgBox = styled.div`
  position: relative;
  min-width: 700px;
`

const NumbersBox = styled.div`
  width: 80%;
  height: 50px;
  transform: rotate(-20deg);
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 30%;
  padding: 4px;
  left: 15%;
`

const OffertImg = ({ houseData, check, displayInfo }) => {
  const data = useStaticQuery(query)
  return (
    <OffertImgBox>
      <Img fluid={data.file.childImageSharp.fluid} alt="Interactive map" />
      <NumbersBox>
        <div style={{ display: "flex", width: `30%`, justifyContent: "space-between" }}>
          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[7]["node"]["numerDomu"]}
            data={houseData[7]["node"]}
            avaliable={houseData[7]["node"]["dostepnosc"]}
          />
          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[6]["node"]["numerDomu"]}
            data={houseData[6]["node"]}
            avaliable={houseData[6]["node"]["dostepnosc"]}
          />
        </div>

        <div style={{ display: "flex", width: `20%`, justifyContent: "space-between", marginLeft: `9%` }}>
          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[5]["node"]["numerDomu"]}
            data={houseData[5]["node"]}
            avaliable={houseData[5]["node"]["dostepnosc"]}
          />

          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[4]["node"]["numerDomu"]}
            data={houseData[4]["node"]}
            avaliable={houseData[4]["node"]["dostepnosc"]}
          />
        </div>

        <div style={{ display: "flex", width: `15%`, justifyContent: "space-between", marginLeft: `7%` }}>
          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[3]["node"]["numerDomu"]}
            data={houseData[3]["node"]}
            avaliable={houseData[3]["node"]["dostepnosc"]}
          />

          <HouseNumber
            checked={check}
            displayInfo={displayInfo}
            number={houseData[2]["node"]["numerDomu"]}
            data={houseData[2]["node"]}
            avaliable={houseData[2]["node"]["dostepnosc"]}
          />
        </div>

        <HouseNumber
          checked={check}
          displayInfo={displayInfo}
          number={houseData[1]["node"]["numerDomu"]}
          data={houseData[1]["node"]}
          avaliable={houseData[1]["node"]["dostepnosc"]}
        />
        <HouseNumber
          checked={check}
          displayInfo={displayInfo}
          number={houseData[0]["node"]["numerDomu"]}
          data={houseData[0]["node"]}
          avaliable={houseData[0]["node"]["dostepnosc"]}
        />
      </NumbersBox>
    </OffertImgBox>
  )
}

OffertImg.propTypes = {
  houseData: PropTypes.array,
  check: PropTypes.bool,
  displayInfo: PropTypes.bool,
}

const query = graphql`
  query Visualization {
    file(relativePath: { eq: "visualization/visualization.png" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default OffertImg
