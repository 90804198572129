import React from "react"
import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"
import TextHeader from "../atoms/textHeader"
import { Link } from "gatsby"

const HouseInfoBoxAnmiationUp = keyframes`
    0%{
        opacity: 0;
        transform: translateY(-50px);
    }

    100%{
        opacity: 1;
        transform: translateY(0px);
    }
`

const HouseInfoBoxAnmiationDown = keyframes`
    0%{
        opacity: 0;
        transform: translateY(390px);
    }

    100%{
        opacity: 1;
        transform: translateY(440px);
    }
`

const HouseInfoBox = styled.div`
  width: 270px;
  height: 320px;
  position: absolute;
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.secondary};
  bottom: 50px;
  display: ${({ visible }) => (visible ? "block" : "none")};
  animation: 0.4s ease forwards;
  animation-name: ${({ number }) => (number <= 5 ? HouseInfoBoxAnmiationDown : HouseInfoBoxAnmiationUp)};
  z-index: 4;
  @media (max-width: 1279px) {
    opacity: 0;
    display: none;
  }
  &::before {
    content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    top: -30px;
    left: 0;
  }
  &::after {
    content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -30px;
    left: 0;
    z-index: 4;
  }
`

const Avaliable = styled(TextHeader)`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 0;
`

const GrayParagraph = styled.span`
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
`
const Price = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 1.4rem;
`
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const ListElement = styled.li`
  margin-top: 5px;
  margin-bottom: 5px;
  &:last-child {
    margin-top: 10px;
  }
`
const ShowMoreButton = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 35px;
  padding: 20px;
  border-radius: 90px;
  text-decoration: none;
  transition: 0.4s ease;
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  z-index: 4;
  color: default;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }
`

const HouseInfo = ({ visibile, number, data }) => {
  return (
    <HouseInfoBox visible={visibile} number={number} data={data}>
      <GrayParagraph>DOM NR. {number}</GrayParagraph>
      <Avaliable>{data.dostepnosc === "Dostepny" ? "Dostępny" : data.dostepnosc}</Avaliable>
      <List>
        <ListElement>
          <GrayParagraph>Powierzchnia: </GrayParagraph>
          <span>
            {data.powierzchnia} m<sup>2</sup>
          </span>
        </ListElement>
        <ListElement>
          <GrayParagraph>Powierzchnia działki: </GrayParagraph>
          <span>
            {data.powierzchniaDzialki} m<sup>2</sup>
          </span>
        </ListElement>
        <ListElement>
          <GrayParagraph>Garaż: </GrayParagraph>
          <span>{data.garaz}</span>{" "}
        </ListElement>
        <ListElement>
          <GrayParagraph>Termin oddania: </GrayParagraph>
          <span>{data.terminOddania}</span>
        </ListElement>
        <ListElement>
          <Price>{data.cena}zł </Price>
          <GrayParagraph>({data.cenaZaMetr}zł/m2)</GrayParagraph>
        </ListElement>
      </List>
      <ShowMoreButton to="/1/house" state={{ data: data, number: number }}>
        Zobacz więcej
      </ShowMoreButton>
    </HouseInfoBox>
  )
}

HouseInfo.propTypes = {
  visibile: PropTypes.bool,
  number: PropTypes.number,
  data: PropTypes.object,
}

export default HouseInfo
