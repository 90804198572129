import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import TextHeader from "../atoms/textHeader"
import { GatsbyImage } from "gatsby-plugin-image"

const VizImageBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
`

const VizDataContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 350px;
  margin-bottom: 50px;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1279px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const VizImg = styled(GatsbyImage)`
  height: auto;
  width: 100%;
  max-width: 800px;
  aspect-ratio: 1;
`

const VizTableBox = styled.div`
  min-width: 370px;
  margin-left: 15px;
  @media (max-width: 1279px) {
    width: 100%;
    max-width: 600px;
    margin-left: 0;
    margin-top: 15px;
  }
`

const VizTableHeader = styled.p`
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: white;
  display: block;
  padding: 10px;
  font-weight: bold;
`

const VizTableSum = styled(VizTableHeader)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const VizHeader = styled(TextHeader)`
  font-weight: 100;
`

const DataList = styled.ul`
  &:nth-of-type(1) {
    border-top: 1px solid black;
  }
`

const ListEl = styled.li`
  list-style: decimal;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 2px;
  padding-left: 15px;
  padding-right: 15px;
`

const VizImage2D = ({ imageSrc, tableData }) => {
  console.log(tableData)
  return (
    <VizImageBox>
      <VizHeader>Wizualizacja pomieszczeń</VizHeader>
      <VizDataContainer>
        <VizImg
          image={imageSrc ? imageSrc.gatsbyImageData : ""}
          alt="2D vizualization"
          imgStyle={{ objectFit: "contain" }}
        />
        <VizTableBox>
          <VizTableHeader>POWIERZCHNIA UŻYTKOWA</VizTableHeader>
          <DataList>
            {tableData
              ? tableData.map((el, index) => (
                  <ListEl key={index}>
                    <span style={{ textTransform: "uppercase" }}>
                      {index + 1}. {el.name}
                    </span>
                    <span>
                      {el.area} m<sup>2</sup>
                    </span>
                  </ListEl>
                ))
              : ""}
          </DataList>
          <VizTableSum>
            <span>RAZEM</span>
            <span>
              {tableData
                ? tableData
                    .map((el) => el.area)
                    .reduce((a, b) => a + b)
                    .toFixed(2)
                : ""}{" "}
              m<sup>2</sup>
            </span>
          </VizTableSum>
        </VizTableBox>
      </VizDataContainer>
    </VizImageBox>
  )
}

VizImage2D.propTypes = {
  imageSrc: PropTypes.object,
  tableData: PropTypes.array,
}

export default VizImage2D
