import styled from "styled-components"

const Button = styled.a`
  background-color: ${({ color, theme }) => (color ? color : theme.colors.primary)};
  color: ${({ textColor }) => (textColor ? textColor : "white")};
  padding: 20px 60px;
  border-radius: 90px; //90px
  border-color: black;
  display: inline-block;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
  @media (max-width: 770px) {
    display: block;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 575px) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
  }
`

export default Button
