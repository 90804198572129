import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Layout from "../../components/organisms/layout"
import Offert from "../../components/organisms/offert"
import GoBackButton from "../../components/molecules/goBackButton"
import HouseDetails from "../../components/organisms/houseDetails"
import VizImage2D from "../../components/molecules/vizImage2D"
import VizImage3D from "../../components/molecules/vizImage3D"
import AOS from "aos"
import "aos/dist/aos.css"

const House = (props) => {
  const [houseData, setHouseData] = useState("")
  const [houseNumber, setHouseNumber] = useState("")

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (props.location.state) {
      setHouseData(props.location.state.data)
      setHouseNumber(props.location.state.number)
    }
  })

  return (
    <Layout>
      <GoBackButton />
      {props.location.state ? (
        <>
          <HouseDetails houseData={houseData} houseNumber={houseNumber} />
          <VizImage2D imageSrc={houseData.wizualizacjaDwaD} tableData={houseData.powierzchniaUzytkowa} />
          <VizImage3D imageSrc={houseData.wizualizacjaTrzyD} />
          <Offert displayInfo={false} />
        </>
      ) : (
        <Offert />
      )}
    </Layout>
  )
}

House.propTypes = {
  props: PropTypes.object,
  location: PropTypes.object,
}

export default House
