import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import HouseInfo from "./houseInfo"
import { Link } from "gatsby"
import { handleColorType } from "../atoms/handleColorType"

const NumberBox = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid ${({ avaliable }) => handleColorType(avaliable)};
  border-radius: 100%;
  visibility: ${({ checked, avaliable }) => (checked === true && avaliable !== "Dostepny" ? "hidden" : "visible")};
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transform: rotate(20deg);
  z-index: 5;
  @media (max-width: 991px) {
    width: 20px;
    height: 20px;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    background-color: ${({ avaliable }) => handleColorType(avaliable)};
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    transition: 0.2s ease-in-out;
    z-index: 0;
  }
`

const HouseLink = styled(Link)`
  text-decoration: none;
  color: white;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ checked, avaliable }) => (checked === true && avaliable !== "Dostepny" ? "none" : "auto")};
`

const HouseNumber = ({ number, data, avaliable, checked, displayInfo }) => {
  const [isVisible, setVisibility] = useState(false)

  return (
    <NumberBox
      style={{ zIndex: isVisible ? 4 : 0 }}
      checked={checked}
      avaliable={avaliable}
      active={isVisible}
      onMouseEnter={() => setVisibility(true)}
      onMouseLeave={() => setVisibility(false)}
    >
      {displayInfo === false ? (
        ""
      ) : (
        <HouseInfo visibile={isVisible} number={number} data={data} displayInfo={displayInfo} />
      )}

      <HouseLink
        checked={checked}
        avaliable={avaliable}
        to="/1/house"
        style={{ scrollBehavior: "smooth" }}
        state={{ data: data, number: number }}
      >
        {number}
      </HouseLink>
    </NumberBox>
  )
}

HouseNumber.propTypes = {
  number: PropTypes.number,
  data: PropTypes.object,
  avaliable: PropTypes.string,
  checked: PropTypes.bool,
  displayInfo: PropTypes.bool,
}

export default HouseNumber
