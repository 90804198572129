import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import HouseInfoHorizontal from "../molecules/houseInfoHorizontal"
import HouseContactBox from "../molecules/houseContactBox"
import HouseDetailsHeader from "../molecules/houseDetailsHeader"

const HouseDetailsBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;
  @media (max-width: 991px) {
    width: 90vw;
  }
`

const HouseDetails = ({ houseData, houseNumber }) => {
  return (
    <HouseDetailsBox data-aos="fade-right" data-aos-duration="800">
      <HouseDetailsHeader houseNumber={houseNumber} avaliablity={houseData.dostepnosc} />
      <HouseInfoHorizontal data={houseData} number={houseNumber} />
      <HouseContactBox />
    </HouseDetailsBox>
  )
}

HouseDetails.propTypes = {
  houseData: PropTypes.object,
  houseNumber: PropTypes.number,
}

export default HouseDetails
