import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import TextHeader from "../atoms/textHeader"

const VizImageBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  transition-delay: 0.5s;
`

const VizImageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 350px;
  margin-bottom: 50px;
  position: relative;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
`

const VizImg = styled(GatsbyImage)`
  height: auto;
  width: 100%;
  aspect-ratio: 1;
`

const VizHeader = styled(TextHeader)`
  font-weight: 100;
`

const VizImage3D = ({ imageSrc }) => {
  return (
    <VizImageBox>
      <VizHeader>Wizualizacja 3D</VizHeader>
      <VizImageContainer>
        <VizImg
          image={imageSrc ? imageSrc.gatsbyImageData : ""}
          alt="3D vizualization"
          imgStyle={{ objectFit: "contain" }}
        />
      </VizImageContainer>
    </VizImageBox>
  )
}

VizImage3D.propTypes = {
  imageSrc: PropTypes.object,
}

export default VizImage3D
