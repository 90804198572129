import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const HouseInfoBox = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 1279px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    padding: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
  }
`

const GrayParagraph = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  opacity: 0.4;
`
const Price = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 1.4rem;
`
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const ListElement = styled.li`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.9rem;
`

const PriceBox = styled.div`
  width: 50%;
  height: 60%;
  margin-left: 7px;
  @media (max-width: 575px) {
    height: auto;
    width: 100%;
    margin-top: 7px;
    margin-left: 0;
  }
`

const HouseInfoHorizontal = ({ data }) => {
  return (
    <HouseInfoBox data={data}>
      <List>
        <ListElement>
          <GrayParagraph>Powierzchnia: </GrayParagraph>
          <span>
            {data.powierzchnia} m<sup>2</sup>
          </span>
        </ListElement>
        <ListElement>
          <GrayParagraph>Powierzchnia działki: </GrayParagraph>
          <span>
            {data.powierzchniaDzialki} m<sup>2</sup>
          </span>
        </ListElement>
        <ListElement>
          <GrayParagraph>Garaż: </GrayParagraph>
          <span>{data.garaz}</span>{" "}
        </ListElement>
        <ListElement>
          <GrayParagraph>Termin oddania: </GrayParagraph>
          <span>{data.terminOddania}</span>
        </ListElement>
      </List>
      <PriceBox>
        <GrayParagraph style={{ fontSize: `1.1rem` }}>Cena</GrayParagraph>
        <ListElement>
          <Price>{data.cena}zł </Price>
          <GrayParagraph>
            ({data.cenaZaMetr}zł/m<sup>2</sup>)
          </GrayParagraph>
        </ListElement>
      </PriceBox>
    </HouseInfoBox>
  )
}

HouseInfoHorizontal.propTypes = {
  visibile: PropTypes.bool,
  number: PropTypes.number,
  data: PropTypes.object,
}

export default HouseInfoHorizontal
