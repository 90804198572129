import React from "react"
import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"

const DropDown = keyframes`
    0%{
        transform: translateY(5px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
`

const ShowDown = keyframes`
    0%{
        transform: translateY(-5px);
        opacity: 1;
    }
    100%{
        transform: translateY(0);
        opacity: 0;
    }
`

const OrderChange = keyframes`
    0%{
        order: "";
        opacity: 0;
        transform: translateY(5px);
    }

    100%{
        order: 3;
        transform: translateY(0);
    }
`

const LegendBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
`

const ElementsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  @media (max-width: 551px) {
    flex-wrap: wrap;
  }
`

const HiddenElements = styled(ElementsContainer)`
  animation: 0.3s ease forwards;
  animation-name: ${({ ischecked }) => (ischecked ? ShowDown : DropDown)};
`
const OrderElement = styled(ElementsContainer)`
  animation: 0.3s 0.3s ease forwards;
  animation-name: ${({ ischecked }) => (ischecked ? OrderChange : "")};
  @media (max-width: 779px) {
    animation: none;
  }
`

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 100%;
  position: relative;
  margin-right: 6px;
  margin-left: 6px;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.6;
  }
`

const DotBlack = styled(Dot)`
  border: 1px solid ${({ theme }) => theme.colors.black};
  &::before {
    background-color: ${({ theme }) => theme.colors.black};
  }
`

const DotGray = styled(Dot)`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  filter: brightness(70%);
  &::before {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`

const OffertLegend = ({ ischecked, changeChecked, onClick }) => {
  return (
    <LegendBox>
      <ElementsContainer>
        <input
          type="checkbox"
          onClick={onClick}
          style={{ width: `30px`, height: `30px` }}
          onChange={() => (ischecked === false ? changeChecked(true) : changeChecked(false))}
        />{" "}
        <span>pokaż tylko dostępne</span>
      </ElementsContainer>
      <ElementsContainer>
        <OrderElement ischecked={ischecked}>
          <Dot />
          <span>dostępne</span>
        </OrderElement>

        <HiddenElements ischecked={ischecked}>
          <DotBlack />
          <span>zarezerwowane</span>
        </HiddenElements>

        <HiddenElements ischecked={ischecked}>
          <DotGray />
          <span>sprzedane</span>
        </HiddenElements>
      </ElementsContainer>
    </LegendBox>
  )
}

OffertLegend.propTypes = {
  ischecked: PropTypes.bool,
  changeChecked: PropTypes.func,
  onClick: PropTypes.func,
}

export default OffertLegend
