import React, { useState } from "react"
import styled from "styled-components"
import OffertLegend from "../atoms/offertLegend"
import PageHeader from "../atoms/pageHeader"
import Paragraph from "../atoms/paragraph"
import OffertImg from "../molecules/offertImg"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const OffertBox = styled.div`
  width: 70vw;
  min-width: 360px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    width: 90vw;
  }
`
const ImgContainer = styled.div`
  @media (max-width: 991px) {
    overflow-x: scroll;
  }
`

const Offert = ({ displayInfo }) => {
  const houseData = useStaticQuery(query)

  const [isChecked, setCheck] = useState(false)
  const myRef = React.useRef(null)
  const changeData = (isAvaliable) => {
    setCheck(isAvaliable)
  }
  return (
    <OffertBox id="offert" ref={myRef}>
      <PageHeader>Oferta domów</PageHeader>
      <Paragraph data-aos="fade-right" data-aos-duration="800" data-aos-delay="200">
        Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty.
      </Paragraph>
      <ImgContainer>
        <OffertImg houseData={houseData.allDatoCmsBaranyHouse.edges} check={isChecked} displayInfo={displayInfo} />
      </ImgContainer>
      <OffertLegend onClick={() => myRef.current.scrollIntoView()} changeChecked={changeData} ischecked={isChecked} />
    </OffertBox>
  )
}

Offert.propTypes = {
  displayInfo: PropTypes.object,
}

const query = graphql`
  query MyQuery {
    allDatoCmsBaranyHouse(sort: { order: ASC, fields: numerDomu }) {
      edges {
        node {
          cena
          cenaZaMetr
          dostepnosc
          garaz
          powierzchnia
          powierzchniaDzialki
          numerDomu
          powierzchniaUzytkowa {
            name
            area
          }
          terminOddania
          wizualizacjaDwaD {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          wizualizacjaTrzyD {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default Offert
