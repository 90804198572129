import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { handleColorType } from "../atoms/handleColorType"

const HouseDetailsHeaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`

const HouseHeader = styled.h2`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  position: relative;
  width: 100%;
  margin: 0;
`
const HeaderTextBox = styled.div`
  position: relative;
  &::after {
    content: "";
    width: 100px;
    height: 2px;
    background-color: black;
    position: absolute;
    transition: 0.2s ease;
    bottom: -15px;
    left: 0;
  }
`
const StatusBox = styled.div`
  @media (max-width: 991px) {
    margin-top: 50px;
  }
`
const AvaliableSpan = styled.span`
  color: ${({ avaliablity }) => handleColorType(avaliablity)};
  margin-left: 5px;
`

const HouseDetailsHeader = ({ avaliablity, houseNumber }) => (
  <HouseDetailsHeaderBox>
    <HeaderTextBox>
      <HouseHeader style={{ color: "black" }}>Osiedle &quot;Barany&quot;</HouseHeader>
      <HouseHeader style={{ fontSize: `1.7rem` }}>DOM NR. {houseNumber}</HouseHeader>
    </HeaderTextBox>
    <StatusBox>
      <HouseHeader style={{ color: "black", fontWeight: 100 }}>
        Status:
        <AvaliableSpan avaliablity={avaliablity}>{avaliablity === "Dostepny" ? "Dostępny" : avaliablity}</AvaliableSpan>
      </HouseHeader>
    </StatusBox>
  </HouseDetailsHeaderBox>
)

HouseDetailsHeader.propTypes = {
  avaliablity: PropTypes.string,
  houseNumber: PropTypes.number,
}

export default HouseDetailsHeader
